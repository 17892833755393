<template>
  <div class="page-notification-list">
    <en-table-layout
      :tableData="tableData.data"
      :tips="true"
      @selection-change="handleSelectionChange"
    >
    <div slot="tips">
      <el-form ref="advancedForm" :model="advancedForm" inline label-width="80px">
        <el-form-item>
            <el-button type="primary" size="mini" @click="addNoticFunc">添加</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="settlement(0)">删除勾选项</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="settlement(1)">发布勾选项</el-button>
          </el-form-item>
          <el-form-item>
            <el-select size="mini" v-model="advancedForm.publish_flag" placeholder="状态选择" @clear="clearData" clearable>
              <el-option label="全部" value=""/>
              <el-option label="未发布" value="0"/>
              <el-option label="已发布" value="1"/>
            </el-select>
          </el-form-item>
        <el-form-item>
            <el-input size="mini" v-model="advancedForm.title" placeholder="请输入名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" size="mini" @click="advancedSearchEvent">搜索</el-button>
          </el-form-item>
      </el-form>
    </div>
    <template slot="table-columns">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="title" label="通知标题"/>
      <el-table-column prop="message" label="通知内容"/>
      <el-table-column label="发布时间">
        <template slot-scope="scope">
          <div v-if="scope.row.publish_time">
            {{ scope.row.publish_time | unixToDate}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="publish_flag" label="发布状态">
        <template slot-scope="scope">
          {{ scope.row | returnNotice}}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div v-if="scope.row.publish_flag === 1">
            <el-button type="primary" disabled  size="mini" @click="publishNoticFunc">发布</el-button>
            <el-button type="primary" disabled size="mini" @click="updateNoticFunc(scope.row)">修改</el-button>
            <el-button type="primary" size="mini" @click="deleteNoticFunc(scope.row)">删除</el-button>
          </div>
          <div v-else>
            <el-button type="primary"  size="mini" @click="publishNoticFunc(scope.row)">发布</el-button>
            <el-button type="primary" size="mini" @click="updateNoticFunc(scope.row)">修改</el-button>
            <el-button type="primary" size="mini" @click="deleteNoticFunc(scope.row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </template>
    <el-pagination
        slot="pagination"
        v-if="pageData"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.data_total"
      ></el-pagination>
    </en-table-layout>

    <!-- 添加通知Dialog -->
    <el-dialog title="添加通知" :visible.sync="addNotic">
      <el-form :model="addNoticForm">
        <el-form-item label="通知标题" label-width="120px">
          <el-input v-model="addNoticForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="通知内容" label-width="120px">
          <el-input type="textarea" v-model="addNoticForm.message"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addNotic = false">取 消</el-button>
        <el-button type="primary" @click="submitAddNotice">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改通知 -->
    <el-dialog title="修改通知" :visible.sync="updateNotic">
      <el-form :model="updateNoticForm">
        <el-form-item label="通知标题" label-width="120px">
          <el-input v-model="updateNoticForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="通知内容" label-width="120px">
          <el-input type="textarea" v-model="updateNoticForm.message"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateNotic = false">取 消</el-button>
        <el-button type="primary" @click="submitUpdateNotice">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除通知 -->
    <el-dialog
      title="删除操作"
      :visible.sync="deleteNotic"
      width="30%">
      <span>确定要删除当前的通知消息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteNotic = false">取 消</el-button>
        <el-button type="primary" @click="submitDeleteNotice">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 发布通知 -->
    <el-dialog
      title="操作"
      :visible.sync="publishNotic"
      width="30%">
      <span>确定要发布当前的通知消息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="publishNotic = false">取 消</el-button>
        <el-button type="primary" @click="submitPublishNotice">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Shop from '../../../api/shop'
export default {
  data() {
    return {
      advancedForm: {
        title: '',
        publish_flag: ''
      },
      tableData: '',
      idsList: [],
      /** 列表分页数据 */
      pageData: [],
      params: {
        page_no: 1,
        page_size: 10
      },
      /** 添加通知对话框 */
      addNotic: false,
      addNoticForm: {
        title: '',
        message: '',
        publish_flag: 0
      },
      /** 修改通知 */
      updateNotic: false,
      updateNoticForm: {
        title: '',
        message: '',
        id: '',
        publish_flag: 0
      },
      /** 发布通知 */
      publishNotic: false,
      publishParams: {
        publish_flag: 1,
        id: ''
      },
      /** 删除通知 */
      deleteNotic: false,
      delete_id: ''
    }
  },
  mounted() {
    this.getShopList()
  },
  filters: {
    returnNotice(row) {
      if (row) {
        if (row.publish_flag === 1) {
          return '已发布'
        } else {
          return '未发布'
        }
      }
    }
  },
  methods: {
    /** 批量 */
    settlement(d) {
      if (this.idsList.length) {
        if (d === 0) {
          API_Shop.deleteShopNotice(this.idsList).then(res => {
            if (res) {
              this.$message.success('删除成功')
              this.getShopList()
            } else {
              this.$message.success('删除失败')
            }
          })
        } else {
          this.idsList.forEach(item => {
            const params = {}
            params['id'] = item
            params['publish_flag'] = 1
            API_Shop.putShopNotice(params).then(res => {
              console.log(res)
              this.getShopList()
            }).catch(() => {
              this.$message.error('发布失败')
            })
          })
        }
      } else {
        this.$message.error("请勾选未发布的数据");
      }
    },
    /** 对话框通知 */
    addNoticFunc() {
      this.addNotic = true
    },
    updateNoticFunc(row) {
      this.updateNotic = true
      this.updateNoticForm.title = row.title
      this.updateNoticForm.message = row.message
      this.updateNoticForm.id = row.id
    },
    publishNoticFunc(row) {
      this.publishNotic = true
      this.publishParams.id = row.id
    },
    deleteNoticFunc(row) {
      this.deleteNotic = true
      this.delete_id = row.id
    },
    submitUpdateNotice() {
      API_Shop.putShopNotice(this.updateNoticForm).then(res => {
        if (res.message) {
          this.$message.success('修改成功')
          this.getShopList()
        } else {
          this.$message.success('修改失败')
        }
      })
      this.updateNotic = false
    },
    submitAddNotice() {
      API_Shop.addShopNotice(this.addNoticForm).then(res => {
        if (res.message) {
          this.$message.success('添加成功')
          this.addNoticForm.title = ''
          this.addNoticForm.message = ''
          this.getShopList()
        } else {
          this.$message.success('修改失败')
        }
      })
      this.addNotic = false
    },
    submitDeleteNotice() {
      const delete_array = []
      delete_array.push(this.delete_id)
      API_Shop.deleteShopNotice(delete_array).then(res => {
        if (res) {
          this.$message.success('删除成功')
          this.getShopList()
        } else {
          this.$message.error('删除失败')
        }
      })
      this.deleteNotic = false
    },
    submitPublishNotice() {
      API_Shop.putShopNotice(this.publishParams).then(res => {
        if (res.publish_flag) {
          this.$message.success('发布成功')
          this.getShopList()
        } else {
          this.$message.error('发布失败')
        }
      })
      this.publishNotic = false
    },
    clearData() {
      this.advancedForm.publish_flag = ''
    },
    /** 获取通知列表 */
    getShopList() {
      API_Shop.getShopNotice(this.params).then(res => {
        console.log(res)
        this.tableData = res
      })
    },
    advancedSearchEvent() {
      this.params = {
        ...this.params,
        page_no: 1,
        ...this.advancedForm
      }
      this.getShopList()
    },
    handleSelectionChange(val) {
      const idList = []
      val.forEach(item => {
        if (item.publish_flag === 0) {
          idList.push(item.id)
        }
      })
      this.idsList = idList
      console.log(idList)
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.getShopList()
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.getShopList()
    }
  }
}
</script>

<style lang="scss">
.searchTitle{
  display: flex;
  justify-content: space-between;
}
</style>
